import { createStore } from 'vuex'

export default createStore({
  state: {
    rengou:{make:"",data:""},
    baodan: {make:"",data:""},
    zichan: {make:"",data:""},
    zichanData: {make:"",type:"",data:""},
    selectedAccount:"selectedAccount",
    copyMake:"",
    getDailyEarningsValue:{dailyEarnings: 0, totalEarnings: 0},
    getInviterString:'**********',
    listData:[],
    propertyValue:0,
    noticeDetail:{},
    // 记录用户信息, 下面是用到的键值 记录下了
    userInfo:{
      lockedFlour:"0.0000", //flour积分的锁定资产
      releasedFlour:"0.0000",//flour积分的释放资产
      lockedYeast:"0.0000",  // yeast积分的锁定资产
      releasedYeast:"0.0000",// yeast积分的释放资产
      inviter:"",
      level:"",
      totalEarnings:"0", //总收益
      boughtUsdtAmount:"0", //购买数量
      pizza:"0.0000", //pizza的数量,锁定的是0，没有锁定都是释放后的
      lockedHBTC:"0.0000", //锁定的hbtc。
      releadedHBTC:"0.0000",
      
    },
    languageType:"en",
    update:false
  },
  getters: {
  },
  mutations: {
    // 更新
    update(state,data){
      state.update = data
    },
    // 认购
    rengouzhifu(state,data){
      state.rengou = data
    },
    changePropertyValue(state,data){
      state.propertyValue = data
    },
    changeListData(state,data){
      state.listData = data
    },  
    changeGetInviterString(state,data){
      state.getInviterString = data
    },
    changegetDailyEarningsValue(state,data){
      state.getDailyEarningsValue = data
    },
    // 参与报单
    canyubaodan(state,data){
      state.baodan = data
    },
    // 可领取资产
    zichanlingqu(state,data){
      state.zichan = data
    },
    // 资产页面 转账
    setzichan(state,data){
      state.zichanData = data
    },
    // 存地址
    setAccount(state,data){
      state.selectedAccount = data
    },
    // 存地址
    setCopy(state,data){
      state.copyMake = data
    },
    // 公告列表页传递详情到详情页
    openNoticeDetail(state,data){
      state.noticeDetail = data
    },
    // 获取用户信息
    setUserInfo(state,data){
      state.userInfo = data
    },
    // 保存当前 语言
    setlanguageType(state,data){
      state.languageType = data
    }
  },
  actions: {
  },
  modules: {
  }
})
