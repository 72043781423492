import { createI18n } from 'vue-i18n'
import messagesEn from './i18n/en'
import messagesZh from './i18n/zh'
import messagesKO from './i18n/ko'
import messagesJA from './i18n/ja'

const messages = {
  en: messagesEn,
  zh: messagesZh,
  ko: messagesKO,
  ja: messagesJA
}

const i18n = createI18n({
  locale: 'en', // 默认语言
  fallbackLocale: 'zh', // 如果找不到当前语言的翻译项，将使用备用语言
  messages,
})

export default i18n
