<template>
	<div class="header">
		<div class="user img100" @click="up">
		</div>
		<span class="userName f14">{{userName}}</span>
		<van-popover v-model:show="showPopover" theme="dark" placement="left-start" :actions="actions" @select="changeLanguage">
			<template #reference>
				<span  :class="languageType=='en'? 'picEn':'picEn'"  class="setPic fr pic1 img100"></span>
			</template>
		</van-popover>
		
		<span @click="drawer = true" class="setPic fr pic2 img100"></span>

		<el-drawer
			:class="drawrStyle"
			v-model="drawer"
			size="65%"
			direction="rtl"
			:before-close="handleClose"
		>
			<div class="drawPart">
				<img class="userHaedr" :src="userHaedr">

				<div class="navList">
					<div class="list fc" @click="openPage('/')">
						<div class="navpic navpic1 img100"></div>
						<div class="text f14">{{ $t('header.home') }}</div>
						<div class="more img100"></div>
					</div>
					<div class="list fc"  @click="openPage('homezc')">
						<div class="navpic navpic2 img100"></div>
						<div class="text f14">{{ $t('header.assets') }}</div>
						<div class="more img100"></div>
					</div>
					<div class="list fc"  @click="openPage('homesq')">
						<div class="navpic navpic3 img100"></div>
						<div class="text f14">{{ $t('header.community') }}</div>
						<div class="more img100"></div>
					</div>
					<div class="list fc"  @click="openPage('homegg')">
						<div class="navpic navpic4 img100"></div>
						<div class="text f14">{{ $t('header.announcements') }}</div>
						<div class="more img100"></div>
					</div>
					<div class="list fc" @click="noOpen()">
						<div class="navpic navpic5 img100"></div>
						<div class="text f14">PizzaSwap</div>
						<div class="more img100"></div>
					</div>
					<div class="list fc" @click="toUrl('https://www.pizzaecology.com/pizza-wallet.apk')">
						<div class="navpic navpic6 img100"></div>
						<div class="text f14">PizzaPocket</div>
						<div class="more img100"></div>
					</div>
				</div>
			</div>
		</el-drawer>
		<!--  -->
    <div class="overlay" v-if="isLonding">
			<div class="modal">
				<div class="modal-header">
					<h2>{{ $t('header.bindReferrer') }}</h2>
				</div>
				<div class="modal-body">
					<el-input class="input_class" v-model="isLondingValue"  :placeholder="$t('header.enterDddress')" />
				</div>
				<div class="modal-footer">
					<div class="btn">
					  <div class="myBtn" @click="isLondingFun">{{$t('header.confirm')}}</div>
				  </div>
				</div>
			</div>
		</div>
	</div>

	
</template>

<script setup>
import {computed, onMounted, ref,watch} from "vue"
import { showToast } from 'vant';
import logo  from "@/assets/img/logo.png"
import i18n from '@/i18n' 
import { useRouter,useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
// 引入store
import { useStore } from "vuex";
let store = new useStore()



import * as web3Eth  from "@/api/web3_eth.js"
// let web3Eth = {
// 	init:()=> {},
// 	onConnect:()=> {},
// 	selectedAccount:"sdfsdfsdfsdfsd",
// 	purchaseFlour:()=> {},
// 	invest:()=> {},
// 	claim:()=> {},
// 	transferFlour:()=> {},
// 	transferYeast:()=> {},
// 	copyToClipboard:()=> {},
// }


console.log(web3Eth)
let languageType = ref("en")
const router = useRouter()
const route = useRoute();
let drawer = ref(false)

const actions = [
	{ text: '英文',code:"en" },
	{ text: '中文',code:"zh" },
	{ text: '韩文',code:"ko"},
	{ text: '日文',code:"ja" },
];
// 切换语言

const changeLanguage = (data)=>{
	console.log(data)


	i18n.global.locale = data.code
	languageType.value = data.code

	store.commit("setlanguageType",languageType.value)

}
const up=()=>{
	store.commit('update',!store.state.update)
}
let userHaedr = ref(logo)

// 跳页面
const openPage = (page)=>{

	router.push(page)
	drawer.value= false
}
let userName = ref("0x****0000")

let isLonding = ref(false)
let isLondingValue = ref('')

const isLondingFun = async () =>{
  if(!isLondingValue.value){
		
		showToast(t("message.yqcode"))
		return;
	}
	let value = false
	try {
		value = await web3Eth.bindInviter(isLondingValue.value);
	} catch (error) {
		console.log('邀请码有错')
		showToast(t("message.yqcode"))
	}
	
  if(value){
		isLonding.value = false
	}
}
const toUrl=(url)=>{
	window.open(url)
}
const noOpen=()=>{
	
	showToast(t("message.noOpen"))
}
const urlParams = new URLSearchParams(window.location.search);
const getQueryString=(name) =>{
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");	 
      var r = window.location.href.split('ref=');
	  console.log(r,'refValue')
      if (r.length>1) return unescape(r[1]);
      return null;
    }
// 这里进行初始化
onMounted(async ()=>{
	
  const refValue = getQueryString('ref');	
  let refValues=refValue
	// console.log(refValue,'refValue')
	if(refValue&&refValue.indexOf('&')!=-1){
	
		let a=refValue.split('&')
		console.log(a,'refValue');
		refValues=a[0]
	}
		console.log(refValue,'refValue')
	await web3Eth.init()
	// 建立连接
	await web3Eth.onConnect()
	// 建立连接后获取 钱包
	getAccount(web3Eth.selectedAccount) 
  if(!await web3Eth.isRegister()){
    console.log(111)
    isLonding.value = true
		isLondingValue.value = refValues;
    return;
  }else {
   try {
		let value =  await web3Eth.getDailyEarnings()
		console.log('changegetDailyEarningsValue',value);
		store.commit("changegetDailyEarningsValue",{dailyEarnings: (value.dailyEarnings*1).toFixed(4), totalEarnings: (value.totalEarnings*1).toFixed(4)})
	 } catch (error) {
		showToast('调用失败1')
	 }
   try {
		let str = await web3Eth.getInviter()
		store.commit("changeGetInviterString",str)
	} catch (error) {
    showToast('调用失败2')
	}

	try {
    let listData =  await web3Eth.getFirstLevelInvites()

		store.commit("changeListData",listData)
	} catch (error) {
    showToast('调用失败3')
	}
	getInfo()

	}
})
const getInfo=async()=>{

	try {	
			let userInfo = await web3Eth.getUser()
			// 获取用户信息
       for(let i in userInfo){
				if(userInfo[i]!==undefined || userInfo[i]!==null){
					userInfo[i] = userInfo[i].toString()
				}
			 }	
			//  let pizza= await web3Eth.getPizzaPrincipalAndInterest()
			//  if(pizza>0){
			//  userInfo.pizza=pizza
			//  }
			// console.log(pizza,'getPizzaPrincipalAndInterest');
			 store.commit("setUserInfo", userInfo)
			//  let propertyValue =  (parseInt(userInfo.lockedFlour) + parseInt(userInfo.lockedYeast)) * 5 / 1000
			let propertyValue =  (parseFloat(userInfo.lockedFlour) + parseFloat(userInfo.lockedYeast)).toFixed(4) 
			 store.commit("changePropertyValue",propertyValue)
			

		} catch (error) {
			console.log('调用失败：',error)
			showToast('调用失败')
		}
}
// if(await isRegister()){
//     return;
//   }

//   let inviter = getUrlParameter("ref");
//   if(inviter == '') {
//     alert("请使用邀请链接");
//     return;
//   }

//   bindInviter(inviter);


// 获取钱包 修改成 0x****57FF
const getAccount = (str) =>{
	if(str){
		// 拼接 钱包名 进行展示
		userName.value = str.slice(0,8) + "****" + str.slice(str.length-12,str.length)
	}
}


// 更新用户信息------------------------
watch(computed(()=>store.state.update),(newv)=>{
	console.log(newv.data)
	getInfo()
	upearning()
},{
	deep:true
})

// 认购------------------------
watch(computed(()=>store.state.rengou),(newv)=>{
	console.log(newv.data)
	purchaseFlour(newv.data)
},{
	deep:true
})


// 认购点击支付
const purchaseFlour = (data)=>{
	web3Eth.purchaseFlour(data)
}

// 参与报单------------------------
watch(computed(()=>store.state.baodan),(newv)=>{
	console.log(newv.data)
	invest(newv.data)
},{
	deep:true
})

// 点击确定
const invest = (data)=>{
	web3Eth.invest(data)
}

// 可领取资产------------------------
watch(computed(()=>store.state.zichan),(newv)=>{
	console.log(newv.data)
	 claim(newv.data)
},{
	deep:true
})

// 领取资产
const  claim = (data)=>{

	web3Eth.claim()
	
}

const upearning= async () =>{
	let value =  await web3Eth.getDailyEarnings()
	store.commit("changegetDailyEarningsValue",{dailyEarnings: value.dailyEarnings, totalEarnings: value.totalEarnings})
}
// 资产页面  转账------------------------
watch(
	computed(()=>store.state.zichanData),(newv)=>{
	console.log(newv.data)
	console.log(newv.type)
	if(newv.type =="Flour"){
		transferFlour(newv.data.address,newv.data.quantity)
	}else if(newv.type =="Yeast"){
		transferYeast(newv.data.address,newv.data.quantity)
	}else if(newv.type =="pizza"){
		buyPizza(newv.data.quantity)
	}else if(newv.type =="Hbtc"){
		claimHBTC(newv.data.quantity)
	}

},{
	deep:true
})

// 转账 Flour积分 
const  transferFlour = (address,quantity)=>{
	console.log(address,quantity)
	web3Eth.transferFlour(address, quantity)
}
// 转账 Yeast
const  transferYeast = (address,quantity)=>{
	console.log(address,quantity)
	web3Eth.transferYeast(address, quantity)
}
// 闪兑 Pizza
const  buyPizza = (quantity)=>{
	console.log(quantity)
	web3Eth.buyPizza( quantity)
}
// 闪兑 Pizza
const  claimHBTC = (quantity)=>{
	console.log(quantity)
	web3Eth.claimHBTC( quantity)
}

// 社区页面 ----------------------------------
// 复制链接监听
watch(computed(()=>store.state.copyMake),(newv)=>{
	// console.log("复制：",newv)
	copyToClipboard()
},{
	deep:true
})
//点击复制按钮
const copyToClipboard = ()=>{
	web3Eth.copyToClipboard(window.localStorage.getItem("account"))
}



</script>
<style lang="less" scoped>
.header{
	background: #15121D;
	height: 44px;
	padding: 0 18px 0 16px;
	line-height: 44px;
	display: flex;
    align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
    z-index: 99999;
	box-sizing: border-box;
	.user{
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-image: url('../assets/img/logo.png');
	}
	.userName{
		// color: var(--fc);
		flex: 1;
		margin-left: 10px;
		background: linear-gradient(to right, #C373FA, #8DBFEF); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
				-webkit-background-clip: text;/*将设置的背景颜色限制在文字中*/
				-webkit-text-fill-color: transparent;/*给文字设置成透明*/
	}
	.setPic{
		width: 24px;
		height: 24px;
		display: inline-block;
	}
	.picEn{
		background-image: url('../assets/img/languageEN.png');
		margin-right: 18px;
	}
	.picZh{
		background-image: url('../assets/img/languageCN.png');
		margin-right: 18px;
	}
	.pic2{
		background-image: url('../assets/img/more.png');
	}

	.drawPart{
	
		text-align: center;
	}
	.userHaedr{
		width: 90px;
		height: 90px;
		border-radius: 50%;
		display: inline-block;
	}
	.navList{

		.list{
			padding: 5px 0;
			.navpic{
				width: 24px;
				height: 24px;
			}
			.text{
				flex: 1;
				padding-left: 10px;
				text-align: left;
			}
			.more{
				width: 14px;
				height: 14px;
				background-image: url('../assets/img/arror.png');
			}

			.navpic1{
				background-image: url('../assets/img/navhome.png');
			}
			.navpic2{
				background-image: url('../assets/img/navmetals.png');
			}
			.navpic3{
				background-image: url('../assets/img/navshequ.png');
			}
			.navpic4{
				background-image: url('../assets/img/navnotice.png');
			}
			.navpic5{
				background-image: url('../assets/img/logo.png');
			}
			.navpic6{
				background-image: url('../assets/img/plogo.jpg');
				border-radius: 100%;
			}
		}
	}
}
/* 背景遮罩 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 背景不透明度50%的黑 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* 弹出框容器 */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-width: 90%;
	color:#15121D;
}

/* 弹出框头部 */
.modal-header {
  margin-bottom: 20px;
}

/* 弹出框标题 */
.modal-header h2 {
  margin: 0;
  font-size: 20px;
  text-align: center;
}

/* 弹出框内容区 */
.modal-body {
  margin-bottom: 20px;
}

.modal-body input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}
.modal-body  .el-input__wrapper .el-input__inner{
	  color: #333333;
}

/* 弹出框底部 */
.modal-footer {
  display: flex;
  justify-content: center;
  text-align: center;
}
.input_class{
	border:1px solid #ddd;
	color:#15121D;
}
.el-input__wrapper .is_focus{
  color:#15121D;
}
/* 按钮样式 */
// .btn {
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   background-color: #f1f1f1;
// }

.btn-primary {
  background-color: #007bff;
  color: white;
}

</style>
