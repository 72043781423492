// zh.js
export default {
  message: {
    hello: '你好',
    title: '认购Flour',
    currentPrice: '认购价格：',
    amountReceived: '支付数量：',
    pay: '支付',
    participate: '参与生态',
    enterAmount: '请输入数量',
    invest: '参与报单',
    flourNeeded: '消耗Flour：',
    usdtNeeded: '消耗Usdt：',
    profit: '可获得收益：',
    confirm: '确定',
    claimableAssets: '我的收益',
    todayEarnings: '今日收益',
    totalEarnings: '累计收益',
    claim: '领取',
    directAddresses: '直推地址',
    number: '序号',
    address: '地址',
    copy: '复制',
    bannerInfo: '理财智慧',
    shareLink: "分享链接",
    tips: '去中心化公平公正公开透明',
    contractMessage: 'Pizzaswap合约权限已丢弃!',
    shareDetail: '披萨生态链动未来，区块链技术赋能，构建数字经济信任新生态，透明、安全、高效，引领区块链新时代！',
    noOpen:"敬请期待！",
    success:"操作成功！",
    failed:"操作失败！",
    yqcode:"请输入邀请码",
    notice:"智能财富，引领未来金融新篇章",
    noticeinfo:"在科技飞速发展的时代，人工智能、大数据、区块链等先进技术逐渐渗透到金融领域，为财富管理带来前所未有的变革。智能财富，正成为新时代金融发展的关键词。今天，让我们共同探讨智能财富的魅力所在。"
    
    
    // 其他翻译项...
  },
  header:{
    userName: '用户名',
    languageToggle: 'English',
    home: '首页',
    assets: '资产',
    community: '社区',
    announcements: '公告',
    PizzaSwap: 'PizzaSwap',
    bindReferrer: '绑定推荐人',
    enterQuantity: '请输入数量',
    enterDddress: '请输入地址',
    confirm: '确定'
  },
  assets:{
    pointA: 'a积分',
    pointB: 'b积分',
    pizza: 'pizza',
    hbtc: 'Hbtc',
    transfer: '转账',
    exchange: '兑换Btc（bsc）',
    enterQuantity: '请输入数量',
    enterQuantityh: '请输入Hbtc数量',
    enterQuantityp:"请输入Pizza兑换数量",
    confirm: '确定',
    cancel: '取消',
    quantity: '可用数量',
    bannerInfo: '财富增长',
    tips: '科学助力您的财富健增值',
    assets: '资产',
    address: "接收地址",
    quantity1:"转出数量",
    quantity2:"合成数量",
    enterDddress: '请输入地址',
    exchange1: '兑换数量',
    obtain: '获得数量',
    consumingF: '消耗Flour',
    consumingY: '消耗Yeast',
    synthetic: '合成Pizza',
    exchangeHbt: '兑换Hbtc',
  },
  notice:{
    bannerInfo: '最新动态',
    tips: '权威发布确保信息传递到位',
    detail: '公告详情',
  },
  community:{
    my: "我的",
    shareLink: "分享链接",
    Share: "我的分享",
    id: "id",
    time:"序号",
    quantity:"个人投入",
    rewards:"社区投入",
    inviter:"邀请人",
    address: "地址",
    personalInvestment: "个人投入",
    communityInvestment: "社区投入",
    copy: "复制",
    bannerInfo: '社会风貌',
    tips: '邻里携手打造温馨社区文化圈',
  }
  // 其他模块的翻译项...
}
